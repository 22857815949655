<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_lock_modal.title : "เปลี่ยนสถานะการ Lock Drone"

drone.field.lock_state.help : "ปรับสถานะการ Lock Drone เนื่องจาก Finance Problem"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="$t('drone_lock_modal.title')"
		:ok-text="$t('common.save')"
		:width="400"
		:confirm-loading="loading"
		:cancel-button-props="{ props: { disabled: loading } }"
		:mask-closable="!loading"
		@ok="handleOk"
		@cancel="handleCancel">
		<a-form  :form="formObj">
			<a-form-item :label="$t('drone.field.lock_state')"
				:extra="$t('drone.field.lock_state.help')">
				<a-radio-group
					v-decorator="[
						'lock_state' ,
						{
							rules: [
								{ required: true,message: $tt('validate.required','drone.field.lock_state') },
							],
						},
					]"
					:options="lockOptions"/>
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import {Radio} from "ant-design-vue"
import { updateFieldsValue , getEnumSelectOptions} from '@utils/formUtil'
import {LOCK_STATE} from "@utils/policyUtil"

export default {
	components : {
		"a-radio-group" : Radio.Group,
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		loading : {
			type : Boolean,
			default : false
		}
	} ,
	data() {
		return {
			visible : false,
			drone : {} ,
			lockState : null ,
		}
	} ,
	computed : {
		lockOptions() {
			return getEnumSelectOptions(this,'drone.lock_state',[LOCK_STATE.ACTIVE, LOCK_STATE.LOCKED])
		}
	},
	methods : {
		openModal(drone,lockState) {
			this.drone = drone
			this.lockState = lockState || LOCK_STATE.ACTIVE
			this.visible = true
			this.$nextTick(() => {
				this.formObj.resetFields()
				updateFieldsValue(this.formObj,{
					lock_state : this.lockState
				})
			})
		} ,
		closeModal() {
			this.visible = false
			this.drone = {}
			this.lockState = null
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		} ,
		handleOk() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					if (values.lock_state == this.lockState) {
						this.closeModal()
						return
					}
					this.$emit('submit',{
						formData : {
							...values ,
						} ,
						id : this.drone.id ,
					})
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		handleCancel() {
			if (this.loading)
				return;
			this.closeModal()
		} ,
	}
}
</script>
