
<i18n locale="th" lang="yaml">
action.title : "Drone Action"
action.update : "แก้ไขค่า Drone"
action.update.tooltip : "แก้ไขค่า Drone เช่น Firmware หรือ ชั่วโมงบิน"
action.update.confirm.title : "ยืนยันการแก้ไขค่า Drone"
action.update.confirm.message : "คุณต้องการเปลี่ยนแปลงค่าของ Drone นี้ ?"
action.update.confirm.success : "ทำการเปลี่ยนแปลงค่าของ Drone เรียบร้อย"
action.update.confirm.no_change : "ไม่ได้มีการเปลี่ยนแปลงค่าของ Drone นี้"

action.change_policy : "เปลียน Policy"
action.change_policy.tooltip : "เปลี่ยน Finance Policy"
action.change_policy.confirm.title : "ยืนยันการปรับค่า Finance Policy"
action.change_policy.confirm.message : "คุณต้องการเปลี่ยนแปลงค่า Finance Policy ของ Drone นี้ ?"
action.change_policy.confirm.success : "ทำการเปลี่ยนแปลง Finance Policy ของ Drone เรียบร้อย"

action.lock_state : "Lock / Unlock"
action.lock_state.tooltip : "เปลียนสถานะการ Lock"
action.lock_state.confirm.title : "ยืนยันการเปลี่ยนสถานะการ Lock"
action.lock_state.confirm.message : "คุณต้องการเปลี่ยนแปลงสถานะการ Lock ของ Drone นี้ ?"
action.lock_state.confirm.success : "ทำการเปลี่ยนแปลงสถานะการ Lock ของ Drone เรียบร้อย"

action.register : "ลงทะเบียนเริ่มใช้งาน Drone"
action.register.tooltip : "ลงทะเบียนเริ่มใช้งาน Drone โดยจะระบุที่ลูกค้าซื้อ Drone"

action.change_owner : "เปลี่ยนข้อมูลเจ้าของ Drone"
action.change_owner.tooltip : "เปลี่ยนแปลงข้อมูลเจ้าของ Drone เช่น เจ้าของขายเครื่องไป เป็นต้น"

action.backup : "ยื่นปรับเป็นเครื่องสำรอง"
action.backup.tooltip : "ยื่นปรับให้เครื่องนี้เป็นเครื่องสำรอง เช่น Drone เครื่องนี้ลูกค้าต้องการเปลี่ยนตัวใหม่แทน"
action.backup.modal.title : "@:action.backup"
action.backup.modal.message : "ยืนยันการยื่นปรับให้ Drone {name} เป็นเครื่องสำรอง ? ข้อมูลลูกค้าจะถูกเปลี่ยนเป็นของ RCC แทน"
action.backup.reason.claim : "ลูกค้าขอ Claim ตัวใหม่"
action.backup.reason.allocate : "ทางศูนย์กันไว้เป็นตัวสำรอง"
action.backup.reason.tester : "ทางศูนย์กันไว้เป็นตัวทดสอบ"
action.backup.reason.training : "ทางศูนย์กันไว้เป็นตัวสอนใช้งาน"
action.backup-request.success : "ทำการยื่นปรับให้เป็นเครื่องสำรองเรียบร้อย"


action.decommission : "ยื่นปรับเป็นเครื่องเสื่อมสภาพ"
action.decommission.tooltip : "ยื่นปรับเครื่องนี้เป็นเครื่องที่เสื่อมสภาพ (ยกเลิกการใช้งาน)"
action.decommission.modal.title : "@:action.decommission"
action.decommission.modal.message : "ยืนยันการยื่นปรับให้ Drone {name} เป็นยกเลิกการใช้งาน ?"
action.decommission.reason.fail : "Drone ตัวนี้ไม่สามารถใช้งานได้ตามปกติ"
action.decommission.reason.end : "Drone ตัวนี้เสื่อมสภาพ"
action.decommission-request.success : "ทำการยื่นขอยกเลิกการใช้งาน Drone เรียบร้อย"

drone_action.field.reason : "สาเหตุ"

drone.requested_change.label: "รอการยืนยันจากทาง HGR"

</i18n>

<template>
	<a-card
		v-if="canMakeAction"
		:loading="loading"
		:bordered="false"
		:title="$t('action.title')"
		class="drone-action-card">
		<div v-if="canUpdateStat || canChangePolicy || canChangeLockState" class="mybutton-group-vertical drone-action-group" style="margin-bottom : 24px;">
			<a-button :title="$t('action.update.tooltip')"
				@click="clickUpdate">
				<a-icon type="edit"/>
				{{$t('action.update')}}
			</a-button>

			<a-button v-if="canChangePolicy" :title="$t('action.change_policy.tooltip')" @click="clickChangePolicy">
				{{$t('action.change_policy')}}
			</a-button>
			<a-button v-if="canChangeLockState" :title="$t('action.lock_state.tooltip')" @click="clickChangeLockState">
				{{$t('action.lock_state')}}
			</a-button>
		</div>


		<div class="mybutton-group-vertical drone-action-group">
			<a-button v-if="canRegister" :disabled="disabledRegisterButton || requestedChangeInfo" :title="$t('action.register.tooltip')"
				@click="goRegister">
				<a-icon type="file-add"/>
				{{$t('action.register')}}
			</a-button>
			<a-button v-if="canRegister" :disabled="disabledChangeRegisterButton || requestedChangeInfo" :title="$t('action.change_owner.tooltip')"
				@click="goChangeRegister">
				<a-icon type="swap"/>
				{{$t('action.change_owner')}}
			</a-button>
			<a-button v-if="canUpdateStatus" :disabled="disabledBackupButton || requestedChangeInfo" :title="$t('action.backup.tooltip')"
				@click="askForBackup">
				<BackupIcon class="anticon" />
				{{$t('action.backup')}}
			</a-button>
			<a-button v-if="canUpdateStatus" :disabled="disabledDecommissionButton || requestedChangeInfo" :title="$t('action.decommission.tooltip')"
				type="danger" @click="askForDecommission">
				<DecommissionIcon class="anticon"/>
				{{$t('action.decommission')}}
			</a-button>
			<span v-if="requestedChangeInfo" class="requested-change-label">*{{ this.$t('drone.requested_change.label') }}</span>
		</div>
		<MyConfirmReasonModel
			ref="reasonModalRef"
			:title="modal.title"
			:message="modal.message"
			:reasons="cReasons"
			:reason-label="$t('drone_action.field.reason')"
			:confirm-again="true"
			@cancel="handleModalCancel"
			@confirm="handleModalConfirm">
			<a-icon slot="icon" type="info-circle" class="text-warning" />
		</MyConfirmReasonModel>

		<DroneUpdateStatModal
			v-if="canUpdateStat"
			ref="updateStatModalRef"
			:loading="updateStatLoading"
			@submit="handleUpdateStatSubmit"/>

		<DroneChangePolicyModal
			v-if="canChangePolicy"
			ref="changePolicyModalRef"
			:loading="changePolicyLoading"
			@submit="handleChangePolicySubmit"/>
		<DroneChangeLockModal
			v-if="canChangeLockState"
			ref="changeLockModalRef"
			:loading="changeLockLoading"
			@submit="handleChangeLockSubmit"/>
	</a-card>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import DecommissionIcon from "vue-material-design-icons/AirplaneOff.vue"
import BackupIcon from "vue-material-design-icons/RecycleVariant.vue"
import DroneUpdateStatModal from "@components/drone/DroneUpdateStatModal.vue"
import DroneChangePolicyModal from "@components/drone/DroneChangePolicyModal.vue"
import DroneChangeLockModal from "@components/drone/DroneChangeLockModal.vue"
import MyConfirmReasonModel from "@components/input/MyConfirmReasonModal.vue"
import {POLICY_STATUS} from "@utils/policyUtil"

export default {
	components : {
		DecommissionIcon,BackupIcon,
		DroneUpdateStatModal, MyConfirmReasonModel ,
		DroneChangePolicyModal, DroneChangeLockModal
	} ,
	mixins : [PageMixin] ,
	props : {
		drone : {
			type : null,
			default : () => []
		} ,
		droneModel : {
			type : null,
			default : () => []
		} ,
		changeRequest: {
			type : null,
			default : () => []
		},
		loading : {
			type : Boolean,
			default : false,
		} ,
		policy : {
			type : String,
			default : null,
		} ,
		lockState : {
			type : String,
			default : null,
		} ,
		firmware : {
			type: null,
			default: () => {}
		}
	} ,
	data() {
		return {
			modal : {
				title : '',
				message : '',
				loading : false,
				action : '',
			} ,
			updateStatLoading : false ,
			changeLockLoading : false,
			changePolicyLoading : false,
		}
	},
	computed: {
		requestedChangeInfo() {
			return this.changeRequest.length > 0
		},
		disabledRegisterButton() {
			return this.drone.status !== 'new'
		},
		disabledChangeRegisterButton() {
			return this.drone.status != 'active'
		},
		disabledBackupButton() {
			return this.drone.status == 'backup'
		},
		disabledDecommissionButton() {
			return this.drone.status == 'decommission'
		},
		canMakeAction() {
			return (this.canRegister || this.canUpdateStatus || this.canUpdateStat)
		} ,
		canRegister() {
			return this.drone.id && this.$authorize('register','drone')
		} ,
		canUpdateStatus() {
			return this.drone.id && this.$authorize('updateStatus', 'drone')
		} ,
		canUpdateStat() {
			return this.drone.id && this.$authorize('update', 'drone')
		} ,
		canChangePolicy() {
			return this.drone.id && this.$authorize('changePolicy', 'drone')
		} ,
		canChangeLockState() {
			return this.drone.id  && this.policy === POLICY_STATUS.LOCKED_7D && this.$can('changePolicy', 'drone')
		} ,
		cReasons() {
			switch(this.modal.action) {
				case 'backup-request' :
					return [
						this.$t('action.backup.reason.claim') ,
						this.$t('action.backup.reason.allocate') ,
						this.$t('action.backup.reason.tester') ,
						this.$t('action.backup.reason.training') ,
					]
				case 'decommission-request' :
					return [
						this.$t('action.decommission.reason.fail') ,
						this.$t('action.decommission.reason.end') ,
					]
				default : return []
			}
		} ,
	} ,
	methods : {
		clickUpdate() {
			if (!this.canUpdateStat)
				return
			this.$refs.updateStatModalRef.openModal(this.droneModel,this.drone,this.firmware)
		} ,
		clickChangePolicy() {
			if (!this.canChangePolicy)
				return
			this.$refs.changePolicyModalRef.openModal(this.drone,this.policy)
		} ,
		clickChangeLockState() {
			if (!this.canChangeLockState)
				return
			this.$refs.changeLockModalRef.openModal(this.drone,this.lockState)
		} ,
		goRegister() {
			this.$open({name:'drone/register',params : {serial_no : this.drone.serialNo}})
		} ,
		goChangeRegister() {
			this.$open({name:'drone/change_register',params : {serial_no : this.drone.serialNo}})
		} ,
		askForBackup() {
			if (!this.canUpdateStatus || this.drone.drone_status == 'backup')
				return
			this.modal.title = this.$t('action.backup.modal.title')
			this.modal.message = this.$t('action.backup.modal.message',{name: this.drone.name})
			this.modal.action = 'backup-request'
			this.$refs.reasonModalRef.show()
		} ,
		askForDecommission() {
			if (!this.canUpdateStatus || this.drone.drone_status == 'decommission')
				return
			this.modal.title = this.$t('action.decommission.modal.title')
			this.modal.message = this.$t('action.decommission.modal.message',{name: this.drone.name})
			this.modal.action = 'decommission-request'
			this.$refs.reasonModalRef.show()
		} ,
		handleModalCancel() {
			this.modal.loading = false
			this.modal.action = ''
		} ,
		handleModalConfirm(payload) {
			this.showPageLoading();
			const formData = {
				statusReason : payload.reason
			}
			axios.post(`/api/drones/${this.drone.id}/${this.modal.action}`,formData).then((response)=> {
				this.$emit('update',{
					drone : response.data.data.drone,
					droneOwner : response.data.data.droneOwner,
					changeRequest: response.data.data.changeRequest,
				})
				this.$message.success(this.$t('action.'+this.modal.action+'.success'))
				if (this.$refs.reasonModalRef)
					this.$refs.reasonModalRef.hide()
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.hidePageLoading();
			})
		} ,
		handleUpdateStatSubmit(payload) {
			this.$confirm({
				title : this.$t('action.update.confirm.title') ,
				content : this.$t('action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.updateStatLoading = true
					axios.put("/api/drones/update-stat/"+this.drone.id,payload.formData).then((response) => {
						if (response.data.data.change) {
							this.$message.success(this.$t("action.update.confirm.success"))
							this.$router.go();
						} else {
							this.$message.success(this.$t("action.update.confirm.no_change"))
						}
						this.$refs.updateStatModalRef.closeModal()
					}).catch((error) => {
						this.$refs.updateStatModalRef.formErrors(error)
					}).finally(()=>{
						this.updateStatLoading = false
					})
				}
			})
		} ,
		handleChangePolicySubmit(payload) {
			this.$confirm({
				title : this.$t('action.change_policy.confirm.title') ,
				content : this.$t('action.change_policy.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.changePolicyLoading = true
					axios.post("/api/drones/change-policy/"+this.drone.id,payload.formData).then((response) => {
						this.$message.success(this.$t("action.change_policy.confirm.success"))
						this.$refs.changePolicyModalRef.closeModal()
						this.$router.go();
					}).catch((error) => {
						this.$refs.changePolicyModalRef.formErrors(error)
					}).finally(()=>{
						this.changePolicyLoading = false
					})
				}
			})
		} ,
		handleChangeLockSubmit(payload) {
			this.$confirm({
				title : this.$t('action.lock_state.confirm.title') ,
				content : this.$t('action.lock_state.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.changeLockLoading = true
					this.changePolicyLoading = true
					axios.post("/api/drones/change-lock-state/"+this.drone.id,payload.formData).then((response) => {
						this.$message.success(this.$t("action.lock_state.confirm.success"))
						this.$refs.changeLockModalRef.closeModal()
						this.$router.go();
					}).catch((error) => {
						this.$refs.changeLockModalRef.formErrors(error)
					}).finally(()=>{
						this.changeLockLoading = false
					})
				}
			})
		}

	}
}
</script>
<style lang="less" scoped>
.drone-action-card::v-deep {
	margin-top : 16px;
	> .ant-card-body {
		padding : 16px;
	}
}

.requested-change-label {
	color: red;
}
</style>
