var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.$t('drone_lock_modal.title'),"ok-text":_vm.$t('common.save'),"width":400,"confirm-loading":_vm.loading,"cancel-button-props":{ props: { disabled: _vm.loading } },"mask-closable":!_vm.loading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.formObj}},[_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.lock_state'),"extra":_vm.$t('drone.field.lock_state.help')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'lock_state' ,
					{
						rules: [
							{ required: true,message: _vm.$tt('validate.required','drone.field.lock_state') },
						],
					},
				]),expression:"[\n\t\t\t\t\t'lock_state' ,\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone.field.lock_state') },\n\t\t\t\t\t\t],\n\t\t\t\t\t},\n\t\t\t\t]"}],attrs:{"options":_vm.lockOptions}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }