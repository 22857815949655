<i18n locale="th" lang="yaml" >
message.api_error.title : "Drone S/N : {serialNo} พบข้อผิดพลาด"
message.api_error.description : "พบข้อผิดพลาดในระบบ กรุณาลองใหม่อีกครั้ง หรือ ติดต่อ Support"

message.drone_not_found.title : "Drone S/N : {serialNo} ไม่มีในระบบ"
message.drone_not_found.description : "กรุณาตรวจสอบ S/N ที่ระบุมาอีกครั้ง ผ่าน HGMC"
</i18n>

<template>
	<div class="drone-search-error">
		<DroneIcon class="drone-icon"/>
		<div class="message">
			<div class="title">
				{{$t(msgPrefix + '.title',{ serialNo })}}
			</div>
			<div class="description">
				{{$t(msgPrefix + '.description')}}
			</div>
			<div v-if="!isNotFound" class="detail">
				{{msgDetail}}
			</div>
		</div>
	</div>
</template>

<script>
import DroneIcon from "@components/icon/DroneIcon.vue"
import ApiError from "@utils/errors/ApiError"
export default {
	components : {
		DroneIcon,
	} ,
	props : {
		serialNo : {
			type : String,
			default : "-",
		} ,
		error : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		isNotFound() {
			return this.$notEmpty(this.error) && this.error.code == 404
		} ,
		msgPrefix() {
			return this.isNotFound ? 'message.drone_not_found' : 'message.api_error'
		} ,
		msgDetail() {
			return ApiError.getDisplayErrorMessage(this,this.error)
		}
	}
}
</script>

<style lang="less" scoped>
.drone-search-error {
	margin : 0 auto;
	max-width : 800px;
	background : @white;
	border : 1px solid @border-color-base;
	padding : 24px 24px;
	.mobile & {
		font-size : 0.8em;
	}
}

.drone-icon {
	display: block;
	margin : 0 auto;
	color : lighten(@error-color,15%);
	font-size : 10em;
	&::v-deep > .material-design-icon__svg {
		bottom : 0;
	}
}
.message {
	font-family: @font-family-title;
	text-align: center;
	margin-top : 32px;
	.title {
		font-size : 1.8em;
		color : @primary-color;
		margin-bottom : 2px;
	}
	.description {
		font-size : 1.2em;
		color :@text-muted;

	}
	.detail {
		font-size : 1em;
		color :@text-muted;
		margin-top : 24px;
		text-align: left;
	}

}
</style>
