var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.$t('drone_policy_modal.title'),"ok-text":_vm.$t('common.save'),"width":400,"confirm-loading":_vm.loading,"cancel-button-props":{ props: { disabled: _vm.loading } },"mask-closable":!_vm.loading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.formObj}},[_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.policy'),"extra":_vm.$t('drone.field.policy.help')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'policy' ,
					{
						rules: [
							{ required: true,message: _vm.$tt('validate.required','drone.field.policy') },
						],
					},
				]),expression:"[\n\t\t\t\t\t'policy' ,\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone.field.policy') },\n\t\t\t\t\t\t],\n\t\t\t\t\t},\n\t\t\t\t]"}],attrs:{"options":_vm.policyOptions}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }